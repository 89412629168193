import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  formContainer: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    width: "90%",
  },
  formTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "2.2rem",
  },
  marginBottom: {
    marginBottom: "30px",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const drawerWidth = "10%";
const BASE_URL = "https://wega-api.com";
//const users = ["aniedbalski", "dczernikowska", "mwojtas", "adobrowolska"]; // Możesz dodać więcej użytkowników

const History = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Data, setHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filter, setFilter] = useState("");
  const [editedByFilter, setEditedByFilter] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/list/uzytkownicy`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const sortedUsers = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setUsers(sortedUsers); // Zakładamy, że response.data to tablica użytkowników
    } catch (error) {
      console.error("Błąd podczas pobierania użytkowników:", error);
      setUsers([]); // Zabezpieczenie, żeby lista nie była undefined
    }
  };

  const fetchData = async () => {
    try {
      const [historyResponse, nrRefResponse] = await Promise.all([
        axios.get(`${BASE_URL}/history`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }),
        axios.get(`${BASE_URL}/nrRef`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }),
      ]);

      const historyData = historyResponse.data;
      const nrRefData = nrRefResponse.data;

      const combinedData = historyData.map((historyItem) => {
        const matchingNrRef = nrRefData.find(
          (nrRef) => nrRef.id === historyItem.customId
        );
        return {
          customId: historyItem.customId,
          nrRef: matchingNrRef ? matchingNrRef.nrRef : null,
          fieldName: historyItem.fieldName,
          oldValue: historyItem.oldValue,
          newValue: historyItem.newValue,
          editedBy: historyItem.editedBy,
          editedAt: historyItem.editedAt,
        };
      });

      setHistoryData(combinedData); // Ustawiamy dane raz
      setFilteredData(combinedData); // Ustawiamy dane do filtrowania
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setHistoryData([]);
      setFilteredData([]);
    }
  };

  // Upewniamy się, że dane są pobierane tylko raz
  useEffect(() => {
    fetchData();
    fetchUsers();
  }, []);

  // Paginacja działa na istniejących danych, nie pobiera nowych
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" }); // 🔥 Płynne przewijanie do góry
  };

  // Obsługa zmiany liczby wierszy na stronę
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterApply = () => {
    const trimmedNrRefFilter = filter.trim();

    let filtered = Data;

    // 🔥 Filtrowanie po nrRef
    if (trimmedNrRefFilter) {
      filtered = filtered.filter(
        (item) =>
          item.nrRef && item.nrRef.toString().trim() === trimmedNrRefFilter
      );
    }

    // 🔥 Filtrowanie po edytowane przez
    if (editedByFilter) {
      filtered = filtered.filter(
        (item) => item.editedBy && item.editedBy === editedByFilter
      );
    }

    // 🔥 Filtrowanie po zakresie daty i godziny
    if (dateFrom || dateTo) {
      filtered = filtered.filter((item) => {
        if (!item.editedAt) return false; // Ignorujemy rekordy bez daty

        const editedAtTimestamp = new Date(item.editedAt).getTime();
        const fromTimestamp = dateFrom ? new Date(dateFrom).getTime() : null;
        const toTimestamp = dateTo ? new Date(dateTo).getTime() : null;

        return (
          (!fromTimestamp || editedAtTimestamp >= fromTimestamp) &&
          (!toTimestamp || editedAtTimestamp <= toTimestamp)
        );
      });
    }

    setFilteredData(filtered);
    setPage(0); // Resetujemy paginację po filtrowaniu
  };

  const handleClearFilters = () => {
    setFilter(""); // Resetuje filtr nrRef
    setEditedByFilter(""); // Resetuje filtr edytowane przez
    setDateFrom(""); // Resetuje datę OD
    setDateTo(""); // Resetuje datę DO
    setFilteredData(Data); // Przywraca pełne dane
    setPage(0); // Resetuje paginację
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={() => navigate("/user")}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Paper elevation={3} className={classes.formContainer}>
          <Typography variant="h1" className={classes.formTitle}>
            Historia zmian w zgłoszeniach
          </Typography>
          <div className={classes.filterContainer}>
            <TextField
              label="Filtruj Nr. Ref"
              variant="outlined"
              value={filter}
              onChange={handleFilterChange}
            />
            {/* Pole wyboru "Edytowane przez" */}
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel id="edited-by-label">Edytowane przez</InputLabel>
              <Select
                labelId="edited-by-label"
                value={editedByFilter}
                onChange={(event) => setEditedByFilter(event.target.value)}
                label="Edytowane przez"
              >
                <MenuItem value="">Wszyscy</MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.name}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Od (Data i godzina)"
              type="datetime-local"
              variant="outlined"
              value={dateFrom}
              onChange={(event) => setDateFrom(event.target.value)}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="Do (Data i godzina)"
              type="datetime-local"
              variant="outlined"
              value={dateTo}
              onChange={(event) => setDateTo(event.target.value)}
              InputLabelProps={{ shrink: true }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleFilterApply}
            >
              Szukaj
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleClearFilters}
            >
              Wyczyść filtry
            </Button>
          </div>
          {loading ? (
            <div className={classes.marginBottom}>
              <Typography variant="h6">Loading...</Typography>
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className={classes.marginBottom}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nr. Ref</TableCell>
                    <TableCell>Pole</TableCell>
                    <TableCell>Stara wartość</TableCell>
                    <TableCell>Nowa wartość</TableCell>
                    <TableCell>Edytowana przez</TableCell>
                    <TableCell>Edytowana dnia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key={filteredData.length}>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      // 🔥 Konwersja daty do czytelnego formatu
                      const formattedDate = item.editedAt
                        ? new Date(item.editedAt).toLocaleString("pl-PL", {
                            hour: "2-digit",
                            minute: "2-digit",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                        : "Brak danych"; // Obsługa pustych wartości

                      // 🔥 Upewniamy się, że nie mamy pustych wartości
                      if (!item.nrRef && !item.fieldName && !item.editedAt)
                        return null;

                      return (
                        <TableRow key={`${item.customId}-${index}`}>
                          <TableCell>{item.nrRef || "-"}</TableCell>
                          <TableCell>{item.fieldName || "-"}</TableCell>
                          <TableCell>{item.oldValue || "-"}</TableCell>
                          <TableCell>{item.newValue || "-"}</TableCell>
                          <TableCell>{item.editedBy || "-"}</TableCell>
                          <TableCell>{formattedDate}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Paper>
      </main>
    </div>
  );
};

export default History;
