import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import DatePickerField from "../DatePickerField"; // Import your custom DatePickerField
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // Import the Polish locale

registerLocale("pl", pl); // Register the Polish locale
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
  },
  formContainer: {
    maxWidth: "900px", // Set a fixed width of 750px for the form container
    width: "100%", // Set the width of the form container to 100%
    margin: "auto",
    marginTop: theme.spacing(4),
    padding: theme.spacing(3), // Increase the padding to add more space between inputs
  },
  formTitle: {
    textAlign: "center",
  },
  marginBottom: {
    marginBottom: theme.spacing(2), // Add a margin bottom of 16px to all inputs
  },
  marginUpAndDown: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  stickySearchBox: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    padding: "1rem",
    zIndex: 1,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, opacity 0.3s ease",
  },
  stickySearchBoxHidden: {
    transform: "translateY(-100%)",
    opacity: 0,
  },
}));
const drawerWidth = "10%";
const BASE_URL = "https://wega-api.com";

const Form = () => {
  const classes = useStyles();
  setDefaultLocale("pl"); // Set the default locale to Polish
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const [generatedRefNumber, setGeneratedRefNumber] = useState(null);
  const [importerFilteredList, setImporterFilteredList] = useState([]);
  const [exporterFilteredList, setExporterFilteredList] = useState([]);
  const [uzytkownicyList, setUzytkownicyList] = useState([]);
  const [lokalizacjaList, setLokalizacjaList] = useState([]);
  const [urzadTranzytowyList, setUrzadTranzytowyList] = useState([]);
  const [urzadPrzeznaczeniaList, setUrzadPrzeznaczeniaList] = useState([]);
  const navigate = useNavigate();
  const [listType, setListType] = useState(null); // Track if it's Importer or Eksporter list
  const [listOpen, setListOpen] = useState(false); // Track if the list is open
  //const [listSearchTerm, setListSearchTerm] = useState(""); // Track the search term
  const [automatChecked, setAutomatChecked] = useState(false);
  // Define a state variable to track the dynamically added "Nr. Faktury" fields
  // const [nrFakturyFields, setNrFakturyFields] = useState([{ nrFaktury: "" }]);

  // const [eksporterFields, setEksporterFields] = useState([
  //   { eksporter: "" }, // Default Eksporter 1 field
  // ]);

  const [fields, setFields] = useState([
    { nrTranzytu: "", expirationDate: "" }, // Default fields
  ]);

  // Function to add a new set of "Nr. Tranzytu" and "Data wygaśnięcia dokumentów" fields
  const addFields = () => {
    setFields([...fields, { nrTranzytu: "", expirationDate: "" }]);
  };

  // Function to remove a specific set of fields by index
  const removeFields = (index) => {
    if (index > 0) {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1);
      setFields(updatedFields);
    }
  };

  // Function to handle changes in a specific set of fields by index
  const handleFieldsChange = (fieldName, value, index) => {
    const updatedFields = [...fields];
    updatedFields[index][fieldName] = value;
    setFields(updatedFields);
  };

  // State and functions for Status realizacji selects
  const [statusRealizacjiFields, setStatusRealizacjiFields] = useState([
    "Do przygotowania", // Default Status realizacji 1 field
  ]);

  const handleStatusRealizacjiChange = (value, index) => {
    const updatedFields = [...statusRealizacjiFields];
    updatedFields[index] = value;
    setStatusRealizacjiFields(updatedFields);
  };

  const token = localStorage.getItem("token");
  let username; // Deklaracja zmiennej na wyższym poziomie zakresu

  if (token) {
    const decoded = jwtDecode(token);
    username = decoded.username;
  }

  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    nrRef: "",
    importer: "",
    eksporter: "",
    nrFaktury: "",
    nrTranzytu: "",
    dokPrzedPrzybyciem: "",
    osobaWysylajacaOdprawe: "",
    osobaPrzygotowujacaOdprawe: "",
    nrRejestracyjnyPojazdyKurier: "",
    lokalizacjaTowaru: "",
    SAD: "",
    MRN: "",
    Rewizja: "",
    statusRelizacji: "",
    rodzaj: "",
    pliki: "",
    rodzajTransportu: "",
    importersList: [], // Initialize importersList as an empty array
    eksporterList: [],
    fakturaBox: false,
    listPrzewozowyBox: false,
    kosztyTransportuBox: false,
    informacjeOTowarzeBox: false,
    T1Box: false,
    swiadectwoBox: false,
    brakUpowaznieniaBox: false,
    eoriBox: false,
    createdBy: username,
    urzad_tranzytowy: "",
    urzad_przeznaczenia: "",
  });

  const handleListOpen = (type) => {
    setListType(type);
    setListOpen(true);
  };

  const handleListClose = () => {
    setListOpen(false);
  };

  const handleListSelection = (value) => {
    if (listType === "importer") {
      setFormData((prevData) => ({
        ...prevData,
        importer: value,
      }));
    } else if (listType === "eksporter") {
      setFormData((prevData) => ({
        ...prevData,
        eksporter: value,
      }));
    } else if (listType === "urzad_tranzytowy") {
      setFormData((prevData) => ({
        ...prevData,
        urzad_tranzytowy: value,
      }));
    } else if (listType === "urzad_przeznaczenia") {
      setFormData((prevData) => ({
        ...prevData,
        urzad_przeznaczenia: value,
      }));
    }
    setListOpen(false);
  };

  const handleImportersFetch = async () => {
    setIsFetching(true);
    setTimeout(async () => {
      try {
        const response = await axios.get(`${BASE_URL}/list/importerImport`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        const importersData = response.data;

        const importersList = importersData.map((item) => item.name);

        importersList.sort((a, b) => {
          const prefix = "OS. F";
          const aStartsWithPrefix = a.startsWith(prefix);
          const bStartsWithPrefix = b.startsWith(prefix);

          if (aStartsWithPrefix && !bStartsWithPrefix) {
            return 1; // a idzie za b
          } else if (!aStartsWithPrefix && bStartsWithPrefix) {
            return -1; // a przed b
          } else {
            return a.localeCompare(b, "pl", { sensitivity: "base" });
          }
        });

        setImporterFilteredList(importersList); // Set the filtered list
      } catch (error) {
        console.error("Error fetching importers:", error);
      } finally {
        setIsFetching(false);
      }
    }, 100);
  };

  const handleExportersFetch = async () => {
    setIsFetching(true);
    setTimeout(async () => {
      try {
        const response = await axios.get(`${BASE_URL}/list/eksporterEksport`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        const exportersData = response.data;

        const exportersList = exportersData.map((item) => item.name);

        exportersList.sort((a, b) => {
          const prefix = "OS. F";
          const aStartsWithPrefix = a.startsWith(prefix);
          const bStartsWithPrefix = b.startsWith(prefix);

          if (aStartsWithPrefix && !bStartsWithPrefix) {
            return 1; // a idzie na koniec
          } else if (!aStartsWithPrefix && bStartsWithPrefix) {
            return -1; // b idzie na koniec
          } else {
            return a.localeCompare(b, "pl", { sensitivity: "base" });
          }
        });

        setExporterFilteredList(exportersList); // Ustawienie posortowanej listy
      } catch (error) {
        console.error("Error fetching exporters:", error);
      } finally {
        setIsFetching(false);
      }
    }, 100);
  };

  const handleUsersChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(`${BASE_URL}/list/uzytkownicy`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        // Sort the data alphabetically by name using localeCompare
        const sortedData = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
        });

        setUzytkownicyList(sortedData); // Set the filtered list
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const handleLokalizacjaChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/list/lokalizacjaTransportow`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Sort the data alphabetically by name using localeCompare
        const sortedData = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
        });

        setLokalizacjaList(sortedData); // Set the filtered list
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const handleUrzadTranzytowyChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(`${BASE_URL}/list/urzad_tranzytowy`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        // Sort the data alphabetically by name using localeCompare
        const sortedData = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
        });
        console.log(`Sorted urzad tranzytowy data: ${sortedData}`);

        setUrzadTranzytowyList(sortedData); // Set the filtered list
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const handleUrzadPrzeznaczeniaChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/list/urzad_przeznaczenia`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Sort the data alphabetically by name using localeCompare
        const sortedData = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
        });
        console.log(`Sorted urzad tranzytowy data: ${sortedData}`);

        setUrzadPrzeznaczeniaList(sortedData); // Set the filtered list
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      // Only update formData if value is different
      if (prevData[name] === value) return prevData;

      // Reset specific fields when "rodzaj" changes to a specific type
      if (name === "rodzaj") {
        return {
          ...prevData,
          [name]: value,
          importer: "",
          eksporter: "",
          importersList: [],
          eksporterList: [],
        };
      }

      return { ...prevData, [name]: value };
    });

    // Call fetching functions based on the "rodzaj" selection
    if (name === "rodzaj") {
      if (value === "Import") handleImportersFetch();
      else if (value === "Eksport") handleExportersFetch();
      else if (value === "Tranzyt") {
        handleUrzadTranzytowyChange();
        handleExportersFetch();
        handleUrzadPrzeznaczeniaChange();
      } else {
        handleImportersFetch();
        handleExportersFetch();
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setFormData((prevData) => ({
        ...prevData,
        adresatDokumentow: "Automat",
      }));
    }

    setAutomatChecked(isChecked);
  };

  const handleCheckboxChangeUwagi = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const checkValuesInDatabase = async () => {
    const apiPromises = [];
    const existingValues = [];

    const globalEksporter = formData.eksporter?.trim().toUpperCase(); // może być undefined

    for (let i = 0; i < fields.length; i++) {
      if (typeof fields[i].nrFaktury === "undefined") {
        continue;
      }

      const nrFakturyValues = fields[i].nrFaktury.split(",");

      // jeśli globalny eksporter istnieje, użyj jego, inaczej użyj eksportera z danego wiersza
      const eksporterValue =
        globalEksporter || fields[i].eksporter?.trim().toUpperCase();

      if (!eksporterValue) {
        console.warn(`Brak eksportera dla wiersza ${i}, pomijam...`);
        continue;
      }

      for (const nrFaktury of nrFakturyValues) {
        const trimmedNrFaktury = nrFaktury.trim();

        const apiPromise = axios
          .get(`${BASE_URL}/checkValues`, {
            params: {
              eksporter: eksporterValue,
              nrFaktury: trimmedNrFaktury,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => ({
            response,
            nrFaktury: trimmedNrFaktury,
            eksporter: eksporterValue,
          }));

        apiPromises.push(apiPromise);
      }
    }

    try {
      const results = await Promise.all(apiPromises);
      results.forEach(({ response, nrFaktury, eksporter }) => {
        if (response.data.exists) {
          existingValues.push({
            eksporter,
            nrFaktury,
            nrRef: response.data.nrRef, // nowy klucz z bazy
          });
        }
      });

      return existingValues;
    } catch (error) {
      console.error("Error checking values:", error);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Zablokuj przycisk po kliknięciu
    let showAlert = false;
    let nrRef;
    const aggregatedFormData = {}; // Obiekt do przechowywania zagregowanych danych

    // Validation checks
    if (!formData.emailKorespondencyjny) {
      alert("Proszę wpisać adres email do ticketu.");
      setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
      return;
    }
    if (
      formData.rodzajTransportu === "Na Kołach" &&
      formData.lokalizacjaTowaru === "W DRODZE"
    ) {
      alert(
        `Nie może być wybrany rodzaj transportu "Na Kołach" oraz lokalizacja towaru/urząd celny wyjścia "W DRODZE"`
      );
      setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
      return;
    }

    // Wyrażenie regularne dla adresów e-mail oddzielonych średnikami, przecinkami lub spacjami
    const emailPattern =
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})([;, \s]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

    // Sprawdzenie formatu emaila
    if (!emailPattern.test(formData.emailKorespondencyjny)) {
      alert(
        "Wprowadź poprawny adres email lub listę adresów oddzielonych średnikiem, przecinkiem lub spacją."
      );
      setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
      return;
    }

    if (
      formData.adresatDokumentow === "" ||
      formData.adresatDokumentow == null
    ) {
      alert("Proszę wpisać/wybrać adresata dokumentów.");
      setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
      return;
    }
    if (formData.rodzajTransportu === "") {
      alert("Proszę wybrać rodzaj transportu.");
      setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
      return;
    }
    if (formData.rodzaj === "") {
      alert("Proszę wybrać rodzaj zgłoszenie Import, Eksport lub Tranzyt.");
      setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
      return;
    }
    if (formData.dokPrzedPrzybyciem === "") {
      alert("Proszę wybrać czy były dostarczone dokumenty przed przybyciem.");
      setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
      return;
    }

    const existingValues = await checkValuesInDatabase();
    if (existingValues.length > 0) {
      showAlert = true;
      existingValues.forEach((value) => {
        const currentRodzaj = formData.rodzaj;
        let eksporterValue =
          currentRodzaj === "Eksport" ? formData.eksporter : value.eksporter;

        const alertMessage = `Nie można zapisać danych, ponieważ istnieją już rekordy z podanymi wartościami:\nEksporter: ${eksporterValue}\nNr. Faktury: ${value.nrFaktury},\nIstniejący Nr Ref: ${value.nrRef}`;
        alert(alertMessage);
      });

      setIsSubmitting(false);
      return;
    }

    if (showAlert) {
      return;
    }

    //const newRefNumber = await generateNewRefNumber();

    try {
      // Convert checkbox values from true/false to 1/0
      const checkboxFields = [
        "fakturaBox",
        "listPrzewozowyBox",
        "kosztyTransportuBox",
        "informacjeOTowarzeBox",
        "T1Box",
        "swiadectwoBox",
        "brakUpowaznieniaBox",
        "eoriBox",
      ];

      if (
        formData.rodzajTransportu === "Kurier" &&
        formData.lokalizacjaTowaru === "MU MAN zakład"
      ) {
        formData.rodzajTransportu = "Kurier MU MAN zakład";
      }

      const convertedCheckboxes = checkboxFields.reduce((acc, field) => {
        acc[field] = formData[field] ? 1 : 0;
        return acc;
      }, {});

      const formDataWithDynamicFields = {
        ...formData,
        ...convertedCheckboxes, // Include the converted checkbox values
        created_at: new Date().toISOString(),
      };

      const requests = [];

      if (formData.rodzaj === "Tranzyt") {
        if (formData.waluta == null) {
          alert("Proszę wybrać walutę.");
          setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
          return;
        }

        if (formData.gwarant == null) {
          alert("Proszę wybrać Gwaranta.");
          setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
          return;
        }
        if (formData.waluta == null) {
          alert("Proszę wybrać walutę.");
          setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
          return;
        }

        // Pierwszy request do zapisu danych
        const saveResponse = await axios.post(
          `${BASE_URL}/saveFormTranzyt`,
          formDataWithDynamicFields,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Pobierz nrRef z odpowiedzi
        nrRef = saveResponse.data.nrRef;
        console.log(`Nr ref z odpowiedzi z api: ${nrRef}`);

        // Dodaj nrRef do formDataWithDynamicFields
        formDataWithDynamicFields.nrRef = nrRef;
        console.log(`Całe dane z api z nrRef ${formDataWithDynamicFields}`);

        // Drugi request do wysłania e-maila
        if (username !== "MaciejAdmin" || username !== "testowe") {
          await axios.post(
            `${BASE_URL}/sendEmailTranzyt`,
            formDataWithDynamicFields,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        }
      } else {
        const isImport = formData.rodzaj === "Import";
        for (let i = 0; i < statusRealizacjiFields.length; i++) {
          const dynamicFieldsData = {
            nrFaktury: fields[i].nrFaktury,
            eksporter: isImport
              ? fields[i].eksporter
                ? fields[i].eksporter.toUpperCase()
                : formData.eksporter
                ? formData.eksporter.toUpperCase()
                : ""
              : fields[i].eksporter
              ? fields[i].eksporter.toUpperCase()
              : formData.eksporter
              ? formData.eksporter.toUpperCase()
              : "",
            importer: isImport
              ? formData.importer
                ? formData.importer.toUpperCase()
                : fields[i].importer
                ? fields[i].importer.toUpperCase()
                : ""
              : fields[i].importer
              ? fields[i].importer.toUpperCase()
              : formData.importer
              ? formData.importer.toUpperCase()
              : "",
            nrTranzytu: fields[i].nrTranzytu,
            expirationDate: fields[i].expirationDate,
            statusRealizacji: statusRealizacjiFields[i],
          };

          const formDataWithDynamicFields = {
            ...formData,
            ...dynamicFieldsData,
            ...convertedCheckboxes,
            created_at: new Date().toLocaleString("pl-PL", {
              timeZone: "Europe/Warsaw",
            }),
          };
          if (
            formDataWithDynamicFields.statusRealizacji == null ||
            formDataWithDynamicFields.statusRealizacji === ""
          ) {
            alert("Proszę wybrać status realizacji.");
            setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
            return; // Teraz ta instrukcja zakończy działanie całej funkcji, nie tylko callbacku forEach
          }

          // Pierwszy request do zapisu danych
          const saveResponse = await axios.post(
            `${BASE_URL}/saveForm`,
            formDataWithDynamicFields,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          // Pobierz nrRef z odpowiedzi
          nrRef = saveResponse.data.nrRef;

          // Dodaj nrRef do formDataWithDynamicFields
          formDataWithDynamicFields.nrRef = nrRef;

          // Tworzenie kopii danych
          const currentFormData = { ...formDataWithDynamicFields };

          // Iterujemy przez klucze `currentFormData` i agregujemy dane
          Object.keys(currentFormData).forEach((key) => {
            // Sprawdzanie warunków dla pola `rodzaj`
            if (
              (currentFormData.rodzaj === "Import" &&
                [
                  "importer",
                  "rodzaj",
                  "lokalizacjaTowaru",
                  "emailKorespondencyjny",
                  "dokPrzedPrzybyciem",
                  "osobaWysylajacaOdprawe",
                  "osobaPrzygotowujacaOdprawe",
                  "nrRejestracyjnyPojazdyKurier",
                  "fakturaBox",
                  "listPrzewozowyBox",
                  "kosztyTransportuBox",
                  "informacjeOTowarzeBox",
                  "T1Box",
                  "swiadectwoBox",
                  "brakUpowaznieniaBox",
                  "eoriBox",
                  "createdBy",
                  "adresatDokumentow",
                  "statusRealizacji",
                  "created_at",
                ].includes(key)) ||
              (currentFormData.rodzaj === "Import - wpis do rejestru" &&
                [
                  "importer",
                  "rodzaj",
                  "lokalizacjaTowaru",
                  "emailKorespondencyjny",
                  "dokPrzedPrzybyciem",
                  "osobaWysylajacaOdprawe",
                  "osobaPrzygotowujacaOdprawe",
                  "nrRejestracyjnyPojazdyKurier",
                  "fakturaBox",
                  "listPrzewozowyBox",
                  "kosztyTransportuBox",
                  "informacjeOTowarzeBox",
                  "T1Box",
                  "swiadectwoBox",
                  "brakUpowaznieniaBox",
                  "eoriBox",
                  "createdBy",
                  "adresatDokumentow",
                  "statusRealizacji",
                  "created_at",
                ].includes(key))
            ) {
              // Nie agregujemy tych pól - przypisujemy wartość bez zmiany
              aggregatedFormData[key] = currentFormData[key];
            } else {
              // Agregacja pozostałych pól
              if (aggregatedFormData[key]) {
                aggregatedFormData[key] += `, ${currentFormData[key]}`;
              } else {
                aggregatedFormData[key] = currentFormData[key];
              }
            }
          });
        }
      }

      // Drugi request warunkowy - tylko dla rodzaju "Import"
      if (formDataWithDynamicFields.rodzaj === "Import") {
        if (username !== "MaciejAdmin" || username !== "testowe") {
          requests.push(
            axios.post(`${BASE_URL}/sendEmailImport`, aggregatedFormData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            })
          );
        }
      }

      if (formDataWithDynamicFields.rodzaj === "Eksport") {
        if (username !== "MaciejAdmin" || username !== "testowe") {
          requests.push(
            axios.post(`${BASE_URL}/sendEmailEksport`, aggregatedFormData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            })
          );
        }
      }
      if (
        formDataWithDynamicFields.rodzaj !== "Eksport" &&
        formDataWithDynamicFields.rodzaj !== "Import" &&
        formDataWithDynamicFields.rodzaj !== "Tranzyt"
      ) {
        if (username !== "MaciejAdmin" || username !== "testowe") {
          requests.push(
            axios.post(`${BASE_URL}/sendEmailEksport`, aggregatedFormData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            })
          );
        }
      }

      // Execute all requests simultaneously
      const responses = await Promise.all(requests);
      const allSuccess = responses.every((response) => response.status === 200);

      if (allSuccess) {
        const nrRefFinal =
          aggregatedFormData.nrRef && aggregatedFormData.nrRef.trim() !== ""
            ? aggregatedFormData.nrRef
            : formDataWithDynamicFields.nrRef;

        toast.success(
          `Poprawnie zapisano nowe zgłoszenie o numerze referencyjnym: ${nrRefFinal} i wysłano email!`,
          {
            autoClose: 3000,
            onClose: () => navigate("/user"),
          }
        );
      } else {
        toast.error(
          "Coś poszło nie tak, Skonaktuj się z administratorem storny i opowiedz o błędzie.",
          {
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      console.error("Error during form submission:", error);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);

        // Sprawdź, czy jest wiadomość błędu zwrócona przez serwer
        const errorMessage =
          error.response.data.message ||
          "Wystąpil błąd podczas zapisywania danych, spróbuj ponownie później.";

        // Wyświetl toast z odpowiednim komunikatem błędu
        toast.error(errorMessage, {
          autoClose: 3000,
        });
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("Brak odpowiedzi od serwera. Spróbuj ponownie później.", {
          autoClose: 3000,
        });
      } else {
        console.error("Error setting up request:", error.message);
        toast.error(
          "Wystąpil błąd podczas ustawiania żądania, spróbuj ponownie później.",
          {
            autoClose: 3000,
          }
        );
      }

      setIsSubmitting(false);
    }

    // finally {
    //   setIsSubmitting(false); // Odblokuj przycisk po zakończeniu
    // }
  };

  useEffect(() => {
    // const fetchGeneratedRefNumber = async () => {
    //   const newRefNumber = await generateNewRefNumber();
    //   setGeneratedRefNumber(newRefNumber);
    // };

    handleUsersChange();

    handleLokalizacjaChange();

    // Call the function to fetch the generated reference number when the component mounts
    //fetchGeneratedRefNumber();
  }, []);

  const handleLogout = () => {
    localStorage.clear(); // Czyszczenie localStorage
    navigate("/login"); // Nawigacja na stronę /login
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={() => navigate("/user")}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          {/* Add the Logout button */}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Paper elevation={3} className={classes.formContainer}>
          <Typography variant="h5" className={classes.formTitle}>
            Dodaj nowe zgłoszenie
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              {" "}
              {/* Set justifyContent to "center" */}
              <Grid item xs={12} sm={6}>
                {/* <div>
                  {generatedRefNumber !== null && (
                    <Typography variant="h6" className={classes.formTitle}>
                      Numer referencyjny zgłoszenia: {generatedRefNumber}
                    </Typography>
                  )}
                </div> */}
                <FormControl
                  component="fieldset"
                  fullWidth
                  className={classes.marginUpAndDown}
                >
                  <FormLabel component="legend">Rodzaj transportu*</FormLabel>
                  <RadioGroup
                    name="rodzajTransportu"
                    value={formData.rodzajTransportu}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Kurier"
                      control={<Radio color="primary" />}
                      label="Kurier"
                    />
                    <FormControlLabel
                      value="Na Kołach"
                      control={<Radio color="primary" />}
                      label="Na Kołach"
                    />
                    <FormControlLabel
                      value="W drodze"
                      control={<Radio color="primary" />}
                      label="W drodze"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  fullWidth
                  className={classes.marginBottom}
                >
                  <FormLabel component="legend">
                    Jaki jest to rodzaj odprawy*
                  </FormLabel>
                  <RadioGroup
                    required // Add required attribute
                    aria-label="Czy zostały dostarczone dokumenty przed przybyciem?"
                    name="rodzaj"
                    value={formData.rodzaj}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Import"
                      control={<Radio color="primary" />}
                      label="Import"
                    />
                    <FormControlLabel
                      value="Eksport"
                      control={<Radio color="primary" />}
                      label="Eksport"
                    />
                    <FormControlLabel
                      value="Tranzyt"
                      control={<Radio color="primary" />}
                      label="Tranzyt"
                    />
                    <FormControlLabel
                      value="Wyprowadzenie ze składu"
                      control={<Radio color="primary" />}
                      label="Wyprowadzenie ze składu"
                    />
                    <FormControlLabel
                      value="Wprowadzenie na składu"
                      control={<Radio color="primary" />}
                      label="Wprowadzenie na składu"
                    />
                    <FormControlLabel
                      value="Uszlachetnianie czynne"
                      control={<Radio color="primary" />}
                      label="Uszlachetnianie czynne"
                    />
                    <FormControlLabel
                      value="Uszlachetnianie bierne"
                      control={<Radio color="primary" />}
                      label="Uszlachetnianie bierne"
                    />
                    <FormControlLabel
                      value="Powrotny przywóz"
                      control={<Radio color="primary" />}
                      label="Powrotny przywóz"
                    />
                    <FormControlLabel
                      value="Powrotny wywóz"
                      control={<Radio color="primary" />}
                      label="Powrotny wywóz"
                    />
                    <FormControlLabel
                      value="Eksport - wpis do rejestru"
                      control={<Radio color="primary" />}
                      label="Eksport - wpis do rejestru"
                    />
                    <FormControlLabel
                      value="Import - wpis do rejestru"
                      control={<Radio color="primary" />}
                      label="Import - wpis do rejestru"
                    />
                    <FormControlLabel
                      value="Eksport PL i import inny kraj"
                      control={<Radio color="primary" />}
                      label="Eksport PL i import inny kraj"
                    />
                  </RadioGroup>
                </FormControl>

                {formData.rodzaj === "Import" && (
                  <>
                    <TextField
                      required
                      label="Importer"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("importer")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("importer")}
                      open={listOpen && listType === "importer"}
                    >
                      {importerFilteredList.map((importer, index) => (
                        <MenuItem
                          // Add required attribute
                          key={index}
                          value={importer}
                          onClick={() => handleListSelection(importer)}
                        >
                          {importer}
                        </MenuItem>
                      ))}
                    </TextField>

                    {fields.map((field, index) => (
                      <div key={index}>
                        {/* Eksporter Fields */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            label={`Eksporter ${index + 1}`}
                            name={`eksporter${index}`}
                            value={field.eksporter}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "eksporter",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        {/* Nr. Tranzytu Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Nr. Tranzytu ${index + 1}`}
                            name={`nrTranzytu${index}`}
                            value={field.nrTranzytu}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrTranzytu",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "8px" }}
                          />

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="h8"
                              className={classes.formTitle}
                            >
                              Data wygaśnięcia T1
                            </Typography>
                            <DatePickerField
                              label={`Data wygaśnięcia dokumentów ${index + 1}`}
                              name={`expirationDate${index}`}
                              value={field.expirationDate}
                              autoComplete="off"
                              onChange={(date) =>
                                handleFieldsChange(
                                  "expirationDate",
                                  date,
                                  index
                                )
                              }
                              fullWidth
                              format="dd-MM-yyyy"
                              style={{ flex: 1 }}
                            />
                          </div>
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* Nr. Faktury Field */}
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}

                    {/* Common Add Fields button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addFields}
                      className={classes.marginBottom}
                    >
                      +
                    </Button>
                  </>
                )}
                {formData.rodzaj === "Eksport" && (
                  <>
                    <TextField
                      required
                      label="Eksporter"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("eksporter")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("eksporter")}
                      open={listOpen && listType === "eksporter"}
                    >
                      {exporterFilteredList.map((exporter, index) => (
                        <MenuItem
                          key={index}
                          value={exporter}
                          onClick={() => handleListSelection(exporter)}
                        >
                          {exporter}
                        </MenuItem>
                      ))}
                    </TextField>
                    {fields.map((field, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Importer ${index + 1}`}
                            name={`importer${index}`}
                            value={field.importer}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "importer",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}
                {formData.rodzaj === "Tranzyt" && (
                  <>
                    <TextField
                      label="Importer"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      required
                      label="Eksporter"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("eksporter")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("eksporter")}
                      open={listOpen && listType === "eksporter"}
                    >
                      {exporterFilteredList.map((exporter, index) => (
                        <MenuItem
                          key={index}
                          value={exporter}
                          onClick={() => handleListSelection(exporter)}
                        >
                          {exporter}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">Gwarant*</FormLabel>
                      <RadioGroup
                        aria-label="Gwarant"
                        name="gwarant"
                        value={formData.gwarant}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="SGS"
                          control={<Radio color="primary" />}
                          label="SGS"
                        />
                        <FormControlLabel
                          value="Oregon"
                          control={<Radio color="primary" />}
                          label="Oregon"
                        />
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      required
                      label="nrFaktury"
                      name="nrFaktury"
                      value={formData.nrFaktury}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Waga Netto"
                      name="waga_netto"
                      value={formData.wagaNetto}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Waga Brutto"
                      name="waga_brutto"
                      value={formData.wagaBrutto}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">Waluta*</FormLabel>
                      <RadioGroup
                        aria-label="Waluta"
                        name="waluta"
                        value={formData.waluta}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="EUR"
                          control={<Radio color="primary" />}
                          label="Euro"
                        />
                        <FormControlLabel
                          value="USD"
                          control={<Radio color="primary" />}
                          label="USD"
                        />
                        <FormControlLabel
                          value="Zł"
                          control={<Radio color="primary" />}
                          label="Zł"
                        />
                        <FormControlLabel
                          value="GBP"
                          control={<Radio color="primary" />}
                          label="GBP"
                        />
                      </RadioGroup>
                    </FormControl>

                    <TextField
                      label="Przewidywany urząd tranzytowy"
                      name="urzad_tranzytowy"
                      value={formData.urzad_tranzytowy}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("urzad_tranzytowy")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("urzad_tranzytowy")}
                      open={listOpen && listType === "urzad_tranzytowy"}
                    >
                      {urzadTranzytowyList.map((urzad, index) => (
                        <MenuItem
                          key={urzad.id}
                          value={urzad.name}
                          onClick={() => handleListSelection(urzad)}
                        >
                          {urzad.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label="Urząd celny przeznaczenia"
                      name="urzad_przeznaczenia"
                      value={formData.urzad_przeznaczenia}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("urzad_przeznaczenia")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("urzad_przeznaczenia")}
                      open={listOpen && listType === "urzad_przeznaczenia"}
                    >
                      {urzadPrzeznaczeniaList.map((urzad, index) => (
                        <MenuItem
                          key={urzad.id}
                          value={urzad.name}
                          onClick={() => handleListSelection(urzad)}
                        >
                          {urzad.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <FormControl fullWidth>
                      <InputLabel id={`select-list-label`}>
                        Status realizacji*
                      </InputLabel>
                      <Select
                        required
                        labelId={`select-list-label`}
                        id={`select-list`}
                        value={formData.statusRealizacji} // Use statusRealizacjiFields[index] for value
                        onChange={handleChange}
                        name={`statusRealizacji`}
                        className={classes.marginBottom}
                      >
                        <MenuItem value="Do przygotowania">
                          Do przygotowania
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}

                {formData.rodzaj === "Wyprowadzenie ze składu" && (
                  <>
                    <TextField
                      required
                      label="Eksporter"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("eksporter")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("eksporter")}
                      open={listOpen && listType === "eksporter"}
                    >
                      {exporterFilteredList.map((exporter, index) => (
                        <MenuItem
                          key={index}
                          value={exporter}
                          onClick={() => handleListSelection(exporter)}
                        >
                          {exporter}
                        </MenuItem>
                      ))}
                    </TextField>
                    {fields.map((field, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Importer ${index + 1}`}
                            name={`importer${index}`}
                            value={field.importer}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "importer",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        {/* Nr. Tranzytu Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {formData.rodzaj === "Wprowadzenie na składu" && (
                  <>
                    <TextField
                      required
                      label="Importer"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("importer")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("importer")}
                      open={listOpen && listType === "importer"}
                    >
                      {importerFilteredList.map((importer, index) => (
                        <MenuItem
                          key={index}
                          value={importer}
                          onClick={() => handleListSelection(importer)}
                        >
                          {importer}
                        </MenuItem>
                      ))}
                    </TextField>

                    {fields.map((field, index) => (
                      <div key={index}>
                        {/* Eksporter Fields */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            label={`Eksporter ${index + 1}`}
                            name={`eksporter${index}`}
                            value={field.eksporter}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "eksporter",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        {/* Nr. Tranzytu Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {formData.rodzaj === "Uszlachetnianie czynne" && (
                  <>
                    <TextField
                      required
                      label="Importer"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("importer")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("importer")}
                      open={listOpen && listType === "importer"}
                    >
                      {importerFilteredList.map((importer, index) => (
                        <MenuItem
                          // Add required attribute
                          key={index}
                          value={importer}
                          onClick={() => handleListSelection(importer)}
                        >
                          {importer}
                        </MenuItem>
                      ))}
                    </TextField>

                    {fields.map((field, index) => (
                      <div key={index}>
                        {/* Eksporter Fields */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            label={`Eksporter ${index + 1}`}
                            name={`eksporter${index}`}
                            value={field.eksporter}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "eksporter",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        {/* Nr. Tranzytu Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {formData.rodzaj === "Uszlachetnianie bierne" && (
                  <>
                    <TextField
                      required
                      label="Eksporter"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("eksporter")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("eksporter")}
                      open={listOpen && listType === "eksporter"}
                    >
                      {exporterFilteredList.map((exporter, index) => (
                        <MenuItem
                          key={index}
                          value={exporter}
                          onClick={() => handleListSelection(exporter)}
                        >
                          {exporter}
                        </MenuItem>
                      ))}
                    </TextField>

                    {fields.map((field, index) => (
                      <div key={index}>
                        {/* Eksporter Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Importer ${index + 1}`}
                            name={`importer${index}`}
                            value={field.importer}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "importer",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        {/* Nr. Tranzytu Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {formData.rodzaj === "Powrotny przywóz" && (
                  <>
                    <TextField
                      required
                      label="Importer"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("importer")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("importer")}
                      open={listOpen && listType === "importer"}
                    >
                      {importerFilteredList.map((importer, index) => (
                        <MenuItem
                          // Add required attribute
                          key={index}
                          value={importer}
                          onClick={() => handleListSelection(importer)}
                        >
                          {importer}
                        </MenuItem>
                      ))}
                    </TextField>

                    {fields.map((field, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            label={`Eksporter ${index + 1}`}
                            name={`eksporter${index}`}
                            value={field.eksporter}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "eksporter",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {formData.rodzaj === "Powrotny wywóz" && (
                  <>
                    <TextField
                      required
                      label="Eksporter"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("eksporter")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("eksporter")}
                      open={listOpen && listType === "eksporter"}
                    >
                      {exporterFilteredList.map((exporter, index) => (
                        <MenuItem
                          key={index}
                          value={exporter}
                          onClick={() => handleListSelection(exporter)}
                        >
                          {exporter}
                        </MenuItem>
                      ))}
                    </TextField>
                    {fields.map((field, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Importer ${index + 1}`}
                            name={`importer${index}`}
                            value={field.importer}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "importer",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* Nr. Faktury Field */}
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {formData.rodzaj === "Eksport PL i import inny kraj" && (
                  <>
                    <TextField
                      required
                      label="Eksporter"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("eksporter")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("eksporter")}
                      open={listOpen && listType === "eksporter"}
                    >
                      {exporterFilteredList.map((exporter, index) => (
                        <MenuItem
                          key={index}
                          value={exporter}
                          onClick={() => handleListSelection(exporter)}
                        >
                          {exporter}
                        </MenuItem>
                      ))}
                    </TextField>
                    {fields.map((field, index) => (
                      <div key={index}>
                        {/* Eksporter Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Importer ${index + 1}`}
                            name={`importer${index}`}
                            value={field.importer}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "importer",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {formData.rodzaj === "Eksport - wpis do rejestru" && (
                  <>
                    <FormControl fullWidth className={classes.marginBottom}>
                      <InputLabel id="eksporter-label">Eksporter*</InputLabel>
                      <Select
                        required
                        labelId="eksporter-label"
                        id="eksporter-select"
                        value={formData.eksporter} // Używamy formData.eksporter jako wartości
                        onChange={handleChange}
                        name="eksporter" // Używamy tej samej nazwy, aby zachować spójność
                        disabled={isFetching}
                        className={
                          isFetching ? classes.fadeOut : classes.fadeIn
                        }
                      >
                        <MenuItem value="AUTO1 EUROPEAN CARS B.V EORI NL856915361">
                          AUTO1 European Cars B.V EORI NL856915361
                        </MenuItem>
                        <MenuItem value="AUTO1 CAR EXPORT S.R.L EORI IT04927470239">
                          AUTO1 CAR EXPORT S.R.L EORI IT04927470239
                        </MenuItem>
                        <MenuItem value="AUTO1 CAR TRADE S.R.L EORI IT04927470239">
                          AUTO1 CAR TRADE S.R.L EORI IT04927470239
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Importer"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    {fields.map((field, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {formData.rodzaj === "Import - wpis do rejestru" && (
                  <>
                    <FormControl fullWidth className={classes.marginBottom}>
                      <InputLabel id="importer-label">Importer*</InputLabel>
                      <Select
                        required
                        labelId="importer-label"
                        id="importer-select"
                        value={formData.importer} // Używamy formData.importer jako wartości
                        onChange={handleChange}
                        name="importer" // Używamy tej samej nazwy, aby zachować spójność
                        disabled={isFetching}
                        className={
                          isFetching ? classes.fadeOut : classes.fadeIn
                        }
                      >
                        <MenuItem value="MAN BUS SP. Z O.O.">
                          MAN BUS SP. Z O.O.
                        </MenuItem>
                        <MenuItem value="ADM TRADE SP. Z O.O.">
                          ADM TRADE SP. Z O.O.
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {fields.map((field, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required
                            label={`Eksporter ${index + 1}`}
                            name={`eksporter${index}`}
                            value={field.eksporter}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "eksporter",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}*
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* Nr. Faktury Field */}
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}

                    {/* Common Add Fields button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addFields}
                      className={classes.marginBottom}
                    >
                      +
                    </Button>
                  </>
                )}

                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Czy zostały dostarczone dokumenty przed przybyciem*
                  </FormLabel>
                  <RadioGroup
                    aria-label="Czy zostały dostarczone dokumenty przed przybyciem?"
                    name="dokPrzedPrzybyciem"
                    value={formData.dokPrzedPrzybyciem}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Tak"
                      control={<Radio color="primary" />}
                      label="Tak"
                    />
                    <FormControlLabel
                      value="Nie"
                      control={<Radio color="primary" />}
                      label="Nie"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="select-list-label">
                    Osoba przygotowująca odprawe
                  </InputLabel>
                  <Select
                    labelId="select-list-label"
                    id="select-list"
                    value={formData.osobaPrzygotowujacaOdprawe} // Update this line to use the correct state field
                    onChange={handleChange} // Update the onChange event handler to use the handleChange function
                    name="osobaPrzygotowujacaOdprawe" // Update this line to use the correct state field name
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  >
                    <MenuItem value="">Brak przypisania osoby</MenuItem>
                    {uzytkownicyList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="select-list-label">
                    Osoba wysyłająca odprawe
                  </InputLabel>
                  <Select
                    labelId="select-list-label"
                    id="select-list"
                    value={formData.osobaWysylajacaOdprawe} // Update this line to use the correct state field
                    onChange={handleChange} // Update the onChange event handler to use the handleChange function
                    name="osobaWysylajacaOdprawe" // Update this line to use the correct state field name
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  >
                    <MenuItem value="">Brak przypisania osoby</MenuItem>
                    {uzytkownicyList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="select-list-label">
                    Osoba monitorująca odprawe
                  </InputLabel>
                  <Select
                    labelId="select-list-label"
                    id="select-list"
                    value={formData.osobaMonitorujacaOdprawe} // Update this line to use the correct state field
                    onChange={handleChange} // Update the onChange event handler to use the handleChange function
                    name="osobaMonitorujacaOdprawe" // Update this line to use the correct state field name
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  >
                    <MenuItem value="">Brak przypisania osoby</MenuItem>
                    {uzytkownicyList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Nr. Rejestracyjny Pojazdu/Kuriera"
                  name="nrRejestracyjnyPojazdyKurier"
                  value={formData.nrRejestracyjnyPojazdyKurier}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth // Use fullWidth for TextField to match the width of the Paper container
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                />
                {formData.rodzaj === "Tranzyt" ? (
                  <FormControl fullWidth className={classes.marginBottom}>
                    <InputLabel id="select-list-label">
                      Urząd celny wyjścia*
                    </InputLabel>
                    <Select
                      labelId="select-list-label"
                      id="select-list"
                      value={formData.lokalizacjaTowaru}
                      onChange={handleChange}
                      name="lokalizacjaTowaru"
                      className={classes.marginBottom}
                      required
                    >
                      {lokalizacjaList.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth className={classes.marginBottom}>
                    <InputLabel id="select-list-label">
                      Lokalizacja towaru*
                    </InputLabel>
                    <Select
                      labelId="select-list-label"
                      id="select-list"
                      value={formData.lokalizacjaTowaru}
                      onChange={handleChange}
                      name="lokalizacjaTowaru"
                      className={classes.marginBottom}
                      required
                    >
                      {lokalizacjaList.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <label>
                  Wybierz adresata dokumentów
                  <br />
                </label>
                <label>
                  Automat
                  <Checkbox
                    checked={automatChecked}
                    onChange={handleCheckboxChange}
                    //disabled={!!formData.adresatDokumentow} // Disable the checkbox when TextField has input
                  />
                </label>
                <TextField
                  label="Wpisz mail/numer tel. Jeśli trzeba wysłać numer własny/dokumenty celne ręcznie"
                  name="adresatDokumentow"
                  value={formData.adresatDokumentow}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  InputLabelProps={{
                    style: {
                      fontSize: "0.9rem",
                      transform: "translateY(-3px)",
                    }, // Przesunięcie etykiety do góry
                  }}
                  className={classes.marginBottom}
                  disabled={automatChecked}
                />

                <TextField
                  label="Adres email do ticketu"
                  name="emailKorespondencyjny"
                  value={formData.emailKorespondencyjny}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth // Use fullWidth for TextField to match the width of the Paper container
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                  multiline // Ustawia TextField jako wielowierszowy
                  rows={4} // Liczba wierszy na start (możesz dostosować do swoich potrzeb)
                />
                <label>
                  <br />
                  Oznacz braki w odprawie
                  <br />
                </label>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.fakturaBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="fakturaBox"
                      />
                    }
                    label="Faktura"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.listPrzewozowyBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="listPrzewozowyBox"
                      />
                    }
                    label="List Przewozowy"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.kosztyTransportuBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="kosztyTransportuBox"
                      />
                    }
                    label="Koszty Transportu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.informacjeOTowarzeBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="informacjeOTowarzeBox"
                      />
                    }
                    label="Informacje o Towarze"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.T1Box}
                        onChange={handleCheckboxChangeUwagi}
                        name="T1Box"
                      />
                    }
                    label="T1"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.swiadectwoBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="swiadectwoBox"
                      />
                    }
                    label="Świadectwo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.brakUpowaznieniaBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="brakUpowaznieniaBox"
                      />
                    }
                    label="Upoważnienie"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.eoriBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="eoriBox"
                      />
                    }
                    label="EORI"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting} // Wyłącz przycisk podczas wysyłania
                >
                  {isSubmitting ? "Zapisywanie..." : "Zapisz"}{" "}
                  {/* Możesz wyświetlić inne etykiety w zależności od stanu */}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </main>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Form;
