import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { jwtDecode } from "jwt-decode";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
  Send as SendIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  fetchRowData,
  fetchComments,
  addComment,
  updateField,
  logHistory,
  saveHistory,
  fetchPowodWpisaniaOptions,
} from "./RejestrFunctions"; // Import funkcji z pliku apiService.js
const drawerWidth = "10%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
  },
  fieldBlocksContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    alignItems: "flex-start",
  },
  fieldBlock: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    transition: "background-color 0.3s",
    textAlign: "center",
    color: "white",
    minWidth: "150px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  category: {
    marginBottom: theme.spacing(4),
  },
  categoryHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  dialog: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    maxWidth: "500px",
    margin: "0 auto",
  },
  dialogTitle: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
}));

const categories = {
  "Informacje Podstawowe": [
    "powod_wpisania",
    "nr_MRN",
    "nr_akt",
    "sciezkaDoPliku",
  ],
  "Szczegóły wniosku": [
    "nadawca_odbiorca",
    "odbiorca",
    "podpisany",
    "rodzaj_SAD",
  ],
  "Procedura i Płatnik": ["procedura", "platnik"],
  Terminy: ["data_SAD", "data_wyslania", "data_odebrania"],
};

const fieldLabels = {
  powod_wpisania: "Powód wpisania",
  nr_MRN: "MRN",
  nr_akt: "Numer akt",
  nadawca_odbiorca: "Nadawca",
  odbiorca: "Odbiorca",
  podpisany: "Podpisany",
  rodzaj_SAD: "Rodzaj SAD",
  procedura: "Procedura",
  data_SAD: "Data SAD",
  data_wyslania: "Data wysłania",
  platnik: "Płatnik",
  data_odebrania: "Data odebrania decyzji z UC",
  sciezkaDoPliku: "Ścieżka",
};

const platnikOptions = ["KLIENT", "WEGA"];

const podpisanyOptions = [
  "BRUŚ BARBARA",
  "CZERNIKOWSKA DOMINIKA",
  "DOBROWOLSKA-MITRĘGA ANNA",
  "JAKUBOWSKA MARTA",
  "MAZUR SABINA",
  "MUNIK PIOTR",
  "NIEDBALSKI ADRIAN",
  "NOWAK PRZEMYSŁAW",
  "REDUCHA KATARZYNA",
  "SZKUTNIK AGNIESZKA",
  "WOJTAŚ MILENA",
  "ŁOMŻA PRZEMYSŁAW",
];

const EditWniosek1 = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [rowData, setRowData] = useState(null);
  const [editField, setEditField] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const [powodWpisaniaOptions, setPowodWpisaniaOptions] = useState([]);

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  let username;
  let role;
  if (token) {
    const decoded = jwtDecode(token);
    username = decoded.username;
    role = decoded.role;
  }

  const checkAndUpdateStatus = async (newRowData) => {
    // Sprawdź, czy status nie jest już "Wysłany" oraz czy data_wyslania jest ustawiona
    if (newRowData.status === "Do wysłania" && newRowData.data_wyslania) {
      try {
        await updateField(id, { status: "Wysłany" });
        toast.success("Status został zaktualizowany na Wysłany");
        fetchRowData(id).then(setRowData).catch(console.error); // Pobierz ponownie dane po aktualizacji
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Wystąpił błąd podczas aktualizacji statusu.");
      }
    }
  };

  const handleZaakceptujWniosek = async () => {
    try {
      // Wysłanie żądania do API w celu zmiany statusu na "Do wysłania" oraz weryfikacja na 1
      await updateField(id, {
        status: "Do wysłania",
        weryfikacja: 1,
      });

      await saveHistory({
        id: id,
        username: username, // zakładam, że masz zmienną username w zasięgu
        fieldName: "status",
        oldValue: rowData.status, // poprzednia wartość statusu
        newValue: "Do wysłania",
        timestamp: new Date().toISOString(), // aktualny czas
      });

      toast.success("Status został zmieniony na Do wysłania");

      // Pobranie zaktualizowanych danych wniosku
      const updatedRowData = await fetchRowData(id);
      setRowData(updatedRowData); // Ustawienie nowych danych w stanie komponentu
    } catch (error) {
      console.error("Error changing status to Do wysłania:", error);
      toast.error("Wystąpił błąd podczas zmiany statusu.");
    }
  };

  const handleDoPoprawy = async () => {
    try {
      // 1. Zmiana statusu na "Do poprawy" oraz ustawienie weryfikacji na 0
      await updateField(id, {
        status: "Do poprawy",
        weryfikacja: 0,
      });

      // 2. Zapis do historii zmian
      await saveHistory({
        id: id,
        username: username, // zakładam, że masz zmienną username w zasięgu
        fieldName: "status",
        oldValue: rowData.status, // poprzednia wartość statusu
        newValue: "Do poprawy",
        timestamp: new Date().toISOString(), // aktualny czas
      });

      toast.success("Status został zmieniony na Do poprawy.");
      // 3. Pobranie zaktualizowanych danych wniosku
      const updatedRowData = await fetchRowData(id);
      setRowData(updatedRowData); // Aktualizacja stanu komponentu
    } catch (error) {
      console.error("Error changing status to Do poprawy:", error);
      toast.error("Wystąpił błąd podczas zmiany statusu.");
    }
  };

  const handleDoPoprawyNaWniosekUC = async () => {
    try {
      // Wysłanie żądania do API w celu zmiany statusu na "Do poprawy" oraz weryfikacja na 0
      await updateField(id, {
        status: "Do poprawy na wniosek UC",
        weryfikacja: 1,
      });

      await saveHistory({
        id: id,
        username: username, // zakładam, że masz zmienną username w zasięgu
        fieldName: "status",
        oldValue: rowData.status, // poprzednia wartość statusu
        newValue: "Do poprawy na wniosek UC",
        timestamp: new Date().toISOString(), // aktualny czas
      });

      toast.success(
        "Status został zmieniony na Do poprawy na wniosek UC. Proszę napisz komentarz, z informacją dlaczego jest to Do poprawy na wniosek UC. "
      );

      // Pobranie zaktualizowanych danych wniosku
      const updatedRowData = await fetchRowData(id);
      setRowData(updatedRowData); // Ustawienie nowych danych w stanie komponentu
    } catch (error) {
      console.error(
        "Error changing status to Do poprawy na wniosek UC:",
        error
      );
      toast.error("Wystąpił błąd podczas zmiany statusu.");
    }
  };

  const handleWycofany = async () => {
    try {
      // Wysłanie żądania do API w celu zmiany statusu na "Do poprawy" oraz weryfikacja na 0
      await updateField(id, {
        status: "Wycofany",
        weryfikacja: 0,
      });

      await saveHistory({
        id: id,
        username: username, // zakładam, że masz zmienną username w zasięgu
        fieldName: "status",
        oldValue: rowData.status, // poprzednia wartość statusu
        newValue: "Wycofany",
        timestamp: new Date().toISOString(), // aktualny czas
      });

      toast.success("Status został zmieniony na Wycofany.");

      // Pobranie zaktualizowanych danych wniosku
      const updatedRowData = await fetchRowData(id);
      setRowData(updatedRowData); // Ustawienie nowych danych w stanie komponentu
    } catch (error) {
      console.error("Error changing status to Wycofany:", error);
      toast.error("Wystąpił błąd podczas zmiany statusu.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = await fetchPowodWpisaniaOptions();

        // Sortowanie alfabetyczne po pobraniu danych
        const sortedOptions = options.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setPowodWpisaniaOptions(sortedOptions); // Ustaw posortowane opcje
      } catch (error) {
        toast.error("Błąd podczas pobierania powodów wpisania.");
      }
    };

    fetchData(); // Wywołanie funkcji
  }, []);

  useEffect(() => {
    if (id) {
      fetchRowData(id)
        .then((data) => {
          setRowData(data);
          checkAndUpdateStatus(data); // Sprawdź status po pobraniu danych
        })
        .catch(console.error);
      fetchComments(id).then(setCommentsData).catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const updateStatusIfDataOdebraniaSet = async () => {
      if (
        rowData?.data_odebrania !== null &&
        rowData?.status !== "Zakończony"
      ) {
        const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");
        try {
          await updateField(id, {
            status: "Zakończony",
            data_zakonczenia: currentDateTime,
          });
          toast.success("Wniosek został automatycznie zakończony.");
          const updatedData = await fetchRowData(id); // Pobierz ponownie dane po aktualizacji
          setRowData(updatedData);
        } catch (error) {
          console.error("Error updating status:", error);
          toast.error(
            "Wystąpił błąd podczas automatycznego zakończania wniosku."
          );
        }
      }
    };

    // Wywołaj funkcję, jeśli data_odebrania zostanie ustawiona
    if (rowData?.data_odebrania) {
      updateStatusIfDataOdebraniaSet();
    }
  }, [rowData?.data_odebrania, rowData?.status, id]);

  // Definicja funkcji handleFieldSelect
  const handleFieldSelect = (field) => {
    setEditField(field);
    setEditedValue(rowData[field] || "");
    setIsEditing(true);
  };

  const handleAddComment = async () => {
    if (!commentValue) return;

    const newComment = {
      username: username,
      comment: commentValue,
    };

    try {
      await addComment(id, newComment);
      setCommentsData([...commentsData, newComment]);
      setCommentValue(""); // Wyczyszczenie pola komentarza po dodaniu
      toast.success("Komentarz został dodany!");
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error("Wystąpił błąd podczas dodawania komentarza.");
    }
  };

  const handleEditSave = async () => {
    try {
      if (
        editField === "data_wyslania" &&
        (!rowData.platnik || rowData.platnik === "")
      ) {
        toast.error("Musisz najpierw uzupełnić pole Płatnik!");
        return;
      }

      if (
        editField === "data_wyslania" &&
        (!rowData.weryfikacja || rowData.weryfikacja === "0")
      ) {
        toast.error(
          "Użytkownik plomza lub aszkutnik muszą zaakceptować wniosek!"
        );
        return;
      }
      if (
        editField === "data_odebrania" &&
        (!rowData.data_wyslania || rowData.data_wyslania === "")
      ) {
        toast.error("Najpierw musi zostać uzupełniona data wysłania.");
        return;
      }
      const oldValue = rowData[editField];
      const newValue = [
        "data_wpisania",
        "data_SAD",
        "data_wyslania",
        "data_odebrania",
      ].includes(editField)
        ? moment(editedValue).format("YYYY-MM-DD")
        : editedValue;

      await updateField(id, { [editField]: newValue });

      await logHistory(id, {
        field: editField,
        oldValue: oldValue,
        newValue: newValue,
        username: username,
        timestamp: new Date().toISOString(),
      });

      toast.success("Poprawnie zedytowano dane!");
      setIsEditing(false);

      const updatedRowData = await fetchRowData(id);
      setRowData(updatedRowData);
      checkAndUpdateStatus(updatedRowData); // Przekaż zaktualizowane dane do funkcji sprawdzającej status
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Error updating data.");
    }
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  if (!rowData || !id) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress color="secondary" />
        <Typography variant="body1">Loading...</Typography>
      </div>
    );
  }

  const handleBack = () => {
    navigate("/rejestrWnioskow");
  };

  const handleProbaOAacceptacje = async () => {
    try {
      if (!rowData.sciezkaDoPliku || rowData.sciezkaDoPliku === "") {
        toast.error("Musisz najpierw uzupełnić pole Ścieżka!");
        return;
      }
      await updateField(id, { status: "Do weryfikacji" });

      toast.success("Status został zmieniony na Do weryfikacji");

      const updatedRowData = await fetchRowData(id);
      setRowData(updatedRowData);
    } catch (error) {
      console.error("Error changing status:", error);
      toast.error("Wystąpił błąd podczas zmiany statusu.");
    }
  };

  const handleLogout = () => {
    localStorage.clear(); // Czyszczenie localStorage
    navigate("/login"); // Nawigacja na stronę /login
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={handleBack} key="back">
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli " />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h4" component="h1" className={classes.tableTitle}>
          Szczegóły wniosku o numerze: {rowData.unique_number}
        </Typography>

        {isEditing && (
          <div className={classes.dialog}>
            <Typography variant="h6" className={classes.dialogTitle}>
              Edytuj: {fieldLabels[editField] || editField}
            </Typography>
            {[
              "data_wpisania",
              "data_SAD",
              "data_wyslania",
              "data_odebrania",
            ].includes(editField) ? (
              <TextField
                label={fieldLabels[editField] || editField}
                type="date"
                variant="outlined"
                fullWidth
                value={moment(editedValue).format("YYYY-MM-DD")}
                onChange={(event) => setEditedValue(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : ["platnik"].includes(editField) ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{fieldLabels[editField]}</InputLabel>
                <Select
                  value={editedValue}
                  onChange={(event) => setEditedValue(event.target.value)}
                  label={fieldLabels[editField]}
                >
                  {platnikOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : ["podpisany"].includes(editField) ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{fieldLabels[editField]}</InputLabel>
                <Select
                  value={editedValue}
                  onChange={(event) => setEditedValue(event.target.value)}
                  label={fieldLabels[editField]}
                >
                  {podpisanyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : ["powod_wpisania"].includes(editField) ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{fieldLabels[editField]}</InputLabel>
                <Select
                  value={editedValue}
                  onChange={(event) => setEditedValue(event.target.value)}
                  label={fieldLabels[editField]}
                >
                  {powodWpisaniaOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                label={fieldLabels[editField] || editField}
                variant="outlined"
                fullWidth
                value={editedValue}
                onChange={(event) => setEditedValue(event.target.value)}
              />
            )}
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditSave}
              >
                Zapisz
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleEditCancel}
              >
                Anuluj
              </Button>
            </div>
          </div>
        )}

        {!isEditing &&
          Object.keys(categories).map((category) => (
            <div key={category} className={classes.category}>
              <Typography variant="h6" className={classes.categoryHeading}>
                {category}:
              </Typography>
              <div className={classes.fieldBlocksContainer}>
                {categories[category].map((field) =>
                  field === "podpisany" &&
                  !(role === "admin" || username === "aniedbalski") ? null : (
                    <div
                      key={field}
                      className={classes.fieldBlock}
                      onClick={() => handleFieldSelect(field)}
                    >
                      <Typography className={classes.fieldLabel}>
                        {fieldLabels[field] || field}
                      </Typography>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}

        <hr className={classes.wider} />
        {(rowData.status === "W toku" ||
          rowData.status === "Do poprawy" ||
          rowData.status === "Do poprawy na wniosek UC") && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProbaOAacceptacje}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
              endIcon={<SendIcon />}
            >
              Wyślij prośbę o akceptację
            </Button>
            <hr className={classes.wider} />
          </>
        )}

        {rowData.status === "Do weryfikacji" &&
          (username === "plomza" ||
            username === "Maciej" ||
            username === "MaciejAdmin" ||
            username === "testowe" ||
            username === "aszkutnik") && (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleZaakceptujWniosek}
                style={{
                  marginRight: "10px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Zaakceptuj wniosek
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDoPoprawy}
                style={{
                  marginRight: "10px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Do poprawy
              </Button>
              <hr className={classes.wider} />
            </div>
          )}

        {rowData.status === "Wysłany" && (
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDoPoprawyNaWniosekUC}
              style={{
                marginRight: "10px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Do poprawy na wniosek UC
            </Button>
            <hr className={classes.wider} />
          </div>
        )}

        {rowData.status !== "Wycofany" && rowData.status !== "Zakończony" && (
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleWycofany}
              style={{
                marginRight: "10px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Wycofaj wniosek
            </Button>
            <hr className={classes.wider} />
          </div>
        )}

        <Typography variant="h6">
          <strong>Numer wniosku:</strong> {rowData.unique_number}
        </Typography>
        <Typography variant="h6">
          <strong>Status:</strong> {rowData.status}
        </Typography>
        <Typography variant="h6">
          <strong>Ścieżka:</strong> {rowData.sciezkaDoPliku}
        </Typography>
        <Typography variant="h6">
          <strong>Płatnik:</strong> {rowData.platnik}
        </Typography>
        <Typography variant="h6">
          <strong>Data wpisania:</strong>{" "}
          {moment(rowData.data_wpisania).format("YYYY-MM-DD HH:mm")}
        </Typography>
        <Typography variant="h6">
          <strong>Powód wpisania:</strong> {rowData.powod_wpisania}
        </Typography>
        <Typography variant="h6">
          <strong>Data SAD-u:</strong>{" "}
          {moment(rowData.data_SAD).format("YYYY-MM-DD ")}
        </Typography>
        <hr className={classes.wider} />
        <Typography variant="h6">
          <strong>MRN:</strong> {rowData.nr_MRN}
        </Typography>
        <Typography variant="h6">
          <strong>Nr. akt:</strong> {rowData.nr_akt}
        </Typography>
        <Typography variant="h6">
          <strong>Nadawca:</strong> {rowData.nadawca_odbiorca}
        </Typography>
        <Typography variant="h6">
          <strong>Odbiorca:</strong> {rowData.odbiorca}
        </Typography>
        <Typography variant="h6">
          <strong>Podpisany:</strong> {rowData.podpisany}
        </Typography>
        <Typography variant="h6">
          <strong>Rodzaj SAD-u:</strong> {rowData.rodzaj_SAD}
        </Typography>
        <hr className={classes.wider} />
        <Typography variant="h6">
          <strong>Procedura:</strong> {rowData.procedura}
        </Typography>
        <Typography variant="h6">
          <strong>Kontrolujący:</strong> {rowData.kontrolujacy}
        </Typography>
        <Typography variant="h6">
          <strong>Data wysłania do UC:</strong>{" "}
          {rowData.data_wyslania
            ? moment(rowData.data_wyslania).format("YYYY-MM-DD")
            : ""}
        </Typography>

        <Typography variant="h6">
          <strong>Data odebrania decyzji z UC:</strong>{" "}
          {rowData.data_odebrania
            ? moment(rowData.data_odebrania).format("YYYY-MM-DD")
            : ""}
        </Typography>

        <Typography variant="h6">
          <strong>Data zakończenia:</strong>{" "}
          {rowData.data_zakonczenia
            ? moment(rowData.data_zakonczenia).format("YYYY-MM-DD HH:mm")
            : ""}
        </Typography>

        <hr className={classes.wider} />

        {!isEditing && (
          <div>
            <TextField
              label="Dodaj komentarz"
              variant="outlined"
              fullWidth
              value={commentValue}
              onChange={(event) => setCommentValue(event.target.value)}
              style={{ marginTop: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              style={{ marginTop: "10px" }}
            >
              Dodaj komentarz
            </Button>
            <Typography
              variant="h5"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              Komentarze:
            </Typography>
            <div>
              {commentsData.map((comment, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "20px", // Większy odstęp między komentarzami
                    paddingBottom: "10px", // Opcjonalne: Dodatkowy odstęp wewnątrz komentarza
                    borderBottom: "1px solid #ccc", // Opcjonalne: Linia oddzielająca komentarze
                  }}
                >
                  <Typography variant="body1" style={{ fontSize: "1.1rem" }}>
                    <strong>{comment.username}</strong> - {comment.comment}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {moment(comment.created_at).format("YYYY-MM-DD HH:mm")}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
      <ToastContainer />
    </div>
  );
};

export default EditWniosek1;
