import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { updateUserPrivileges, fetchUserPrivileges } from "./UserService";
import { makeStyles } from "@material-ui/core/styles";
// Użyj makeStyles do stylizacji sticky header
const useStyles = makeStyles({
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: "white", // Możesz ustawić tło na kolor tabeli
    zIndex: 1, // Zapewnia, że nagłówek będzie nad innymi elementami podczas przewijania
  },
});

const PrivilegesModal = ({ open, onClose, userIds }) => {
  const [privilegesData, setPrivilegesData] = useState([]);
  const classes = useStyles();

  // Mapowanie nagłówków interfejsu na nazwy kolumn w bazie danych
  const columnMapping = {
    "Kontrola wewnętrzna": "internalControl",
    Import: "import",
    Eksport: "export",
    "Wyprowadzenie z magazynu": "warehouseOut",
    "Wprowadzenie na magazyn": "warehouseIn",
    "Uszlachetnianie czynne": "activeRefinement",
    "Uszlachetnianie bierne": "passiveRefinement",
    "Powrotny przywóz": "returnImport",
    "Powrotny wywóz": "returnExport",
    "Import - wpis do rejestru": "importRegisterEntry",
    "Eksport - wpis do rejestru": "exportRegisterEntry",
    "Eksport PL + import": "exportImport",
    Tranzyt: "tranzyt",
  };

  const headers = Object.keys(columnMapping); // Lista nagłówków interfejsu

  const loadPrivileges = async () => {
    try {
      const privileges = await fetchUserPrivileges();

      // Sortujemy użytkowników alfabetycznie według `username`
      privileges.sort((a, b) => a.username.localeCompare(b.username));

      setPrivilegesData(privileges);
    } catch (error) {
      console.error("Error fetching privileges:", error);
      toast.error("Błąd podczas pobierania przywilejów.");
    }
  };

  useEffect(() => {
    if (open) {
      loadPrivileges();
    }
  }, [open]);

  const handlePrivilegeChange = (userId, privilege) => {
    setPrivilegesData((prevState) =>
      prevState.map((user) =>
        user.id === userId
          ? {
              ...user,
              [columnMapping[privilege]]:
                user[columnMapping[privilege]] === 1 ? 0 : 1,
            }
          : user
      )
    );
  };

  const savePrivileges = async () => {
    try {
      await Promise.all(
        privilegesData.map(async (user) => {
          const updatedPrivileges = Object.keys(columnMapping).reduce(
            (acc, key) => {
              const dbColumn = columnMapping[key];
              if (user[dbColumn] !== undefined && user[dbColumn] !== null) {
                acc[dbColumn] = user[dbColumn];
              }
              return acc;
            },
            {}
          );

          if (Object.keys(updatedPrivileges).length > 0) {
            await updateUserPrivileges(user.id, updatedPrivileges);
          }
        })
      );
      toast.success("Przywileje zostały zaktualizowane.");
      onClose();
    } catch (error) {
      console.error("Błąd podczas aktualizacji przywilejów:", error);
      toast.error("Błąd podczas aktualizacji przywilejów.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      PaperProps={{
        style: {
          width: "90vw",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle>Zarządzaj Przywilejami</DialogTitle>
      <DialogContent>
        <TableContainer style={{ maxHeight: "70vh", overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.stickyHeader}>Username</TableCell>
                {headers.map((header) => (
                  <TableCell key={header} className={classes.stickyHeader}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {privilegesData.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.username}</TableCell>
                  {headers.map((header) => (
                    <TableCell key={header}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={user[columnMapping[header]] === 1}
                            onChange={() =>
                              handlePrivilegeChange(user.id, header)
                            }
                          />
                        }
                        label={
                          user[columnMapping[header]] === 1 ? "Wyłącz" : "Włącz"
                        }
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={savePrivileges} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivilegesModal;
