import axios from "axios";

// Definiujemy zmienną API_ENDPOINT
const API_ENDPOINT = "https://wega-api.com/importListControl";
const BASE_URL = "https://wega-api.com";

// Funkcja do pobierania nagłówków z tokenem
const getHeaders = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token is not available");
  }
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Funkcja do aktualizacji statusu i wstepniePrzygotowane w bazie danych
export const updateStatusWstepnie = async (id, payload) => {
  try {
    const headers = await getHeaders(); // Pobieramy nagłówki
    const response = await axios.put(
      `${API_ENDPOINT}/updateStatusWstepnieTranzyt/${id}`, // Używamy zdefiniowanego API_ENDPOINT
      payload, // Przesyłamy dane w żądaniu
      { headers } // Nagłówki
    );
    return response.data; // Zwracamy odpowiedź z API
  } catch (error) {
    console.error("Error updating status:", error);
    throw error; // Rzucamy błąd, aby mógł być obsłużony w komponencie
  }
};

export const updateStatusPrzygotowane = async (id, payload) => {
  try {
    const headers = await getHeaders(); // Pobieramy nagłówki
    const response = await axios.put(
      `${API_ENDPOINT}/updateStatusPrzygotowaneTranzyt/${id}`, // Używamy zdefiniowanego API_ENDPOINT
      payload, // Przesyłamy dane w żądaniu
      { headers } // Nagłówki
    );
    return response.data; // Zwracamy odpowiedź z API
  } catch (error) {
    console.error("Error updating status:", error);
    throw error; // Rzucamy błąd, aby mógł być obsłużony w komponencie
  }
};

//Pobieranie danych o przywilejach danego uzytkownika
export const fetchUserPrivileges = async (username) => {
  try {
    // Wysyłamy żądanie do API, przekazując `username` jako parametr
    const response = await axios.get(`${API_ENDPOINT}/${username}/privileges`, {
      headers: await getHeaders(),
    });

    // Zakładamy, że API zwraca cały rekord użytkownika, więc wybieramy tylko przywileje
    const {
      internalControl,
      import: importPrivilege,
      export: exportPrivilege,
      warehouseIn,
      warehouseOut,
      activeRefinement,
      passiveRefinement,
      returnImport,
      returnExport,
      importRegisterEntry,
      exportImport,
    } = response.data;

    // Zwracamy tylko dane o przywilejach użytkownika
    return {
      internalControl,
      import: importPrivilege,
      export: exportPrivilege,
      warehouseIn,
      warehouseOut,
      activeRefinement,
      passiveRefinement,
      returnImport,
      returnExport,
      importRegisterEntry,
      exportImport,
    };
  } catch (error) {
    console.error("Error fetching user privileges:", error);
    // Obsługa błędów - możemy zwrócić null lub rzucić wyjątek w zależności od wymagań
    return null;
  }
};

//FUNKJCE POMOCNICZE

export const fetchData = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetch-tranzyt-list/${id}`, {
      headers: await getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const saveOrUpdateData = async (id, payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/saveOrUpdateTranzyt/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save or update data:", error);
    throw error;
  }
};

export const updateZweryfikowanePrzygotowaneDoWyslania = async (
  id,
  payload,
  statusType
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/update-przygotowane-zweryfikowane-status-${statusType}/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updatePrzygotowaneZweryfikowane = async (
  id,
  payload,
  statusType
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/update-przygotowane-zweryfikowane-${statusType}/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updateStatusDoWeryfikacji = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/update-status-${statusType}-weryfikacja/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updateStatusDoWyslania = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/update-status-${statusType}-do-wyslania/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updateStatusDoPoprawy = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/update-status-${statusType}-do-poprawy/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updatePrzygotowane = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/update-status-${statusType}-przygotowane/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const saveVerifyingData1 = async (id, payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/saveVerifyingData-tranzyt/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save verifying data:", error);
    throw error;
  }
};

export const saveComment = async (id, payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/comments/${id}`, payload, {
      headers: await getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to save comment:", error);
    throw error;
  }
};

export const saveCheckbox = async (id, payload) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/saveCheckbox/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save checkbox:", error);
    throw error;
  }
};

export const getSADAndDrugaWartosc = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/get-SAD-Pik/${id}`, {
      headers: await getHeaders(),
    });
    return {
      SAD: response.data.SAD, // Adjust this based on actual API response
      Plik: response.data.plikiBox, // Adjust this based on actual API response
    };
  } catch (error) {
    console.error("Error fetching SAD and drugaWartosc:", error);
    throw error;
  }
};

export const saveInternalControlDataDates = async (id, payload) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/saveInternalControlDataTranzyt/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save or update data:", error);
    throw error;
  }
};
