import axios from "axios";

const API_ENDPOINT = "https://wega-api.com/rejestrWnioskow"; // Zmienna przechowująca adres API
const API_BASE_URL = "https://wega-api.com";
const getHeaders = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token is not available");
  }
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const createWniosek = async (valuesArray) => {
  try {
    const headers = await getHeaders();

    // Wprowadzenie zmiany do zapytania SQL
    const query = `
      INSERT INTO wnioski (
        data_SAD, nr_MRN, nr_akt, nadawca_odbiorca, odbiorca, podpisany, rodzaj_SAD, procedura, platnik, powod_wpisania, sciezkaDoPliku, data_wpisania, unique_number, status, duplikat
      ) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
    `;

    // Przygotowanie operacji zapisu dla wszystkich wartości
    for (const values of valuesArray) {
      await axios.post(
        API_ENDPOINT + "/create",
        { values, query },
        { headers }
      );
    }

    return { status: 200 };
  } catch (error) {
    throw new Error("Błąd podczas tworzenia wniosku: " + error.message);
  }
};

export const getExistingMRNs = async () => {
  try {
    const headers = await getHeaders();
    const response = await axios.get(API_ENDPOINT + "/existing-mrns", {
      headers,
    });
    return response.data; // Zakładam, że zwracasz listę numerów MRN w formacie tablicy
  } catch (error) {
    throw new Error("Błąd podczas pobierania MRN: " + error.message);
  }
};

export const fetchWnioskiWithHistory = async () => {
  try {
    const headers = await getHeaders();

    // Pobierz dane
    const wnioskiResponse = await axios.get(API_ENDPOINT, { headers });
    const historyResponse = await axios.get(API_ENDPOINT + "/history", {
      headers,
    });
    const commentsResponse = await axios.get(API_ENDPOINT + "/commentsAll", {
      headers,
    });

    // Upewnij się, że dane są tablicami
    const wnioski = Array.isArray(wnioskiResponse.data)
      ? wnioskiResponse.data
      : [];
    const history = Array.isArray(historyResponse.data)
      ? historyResponse.data
      : [];
    const comments = Array.isArray(commentsResponse.data)
      ? commentsResponse.data
      : [];

    // Mapowanie danych z historią i komentarzami
    const wnioskiWithHistoryAndComments = wnioski.map((wniosek) => ({
      ...wniosek,
      history: history.filter((change) => change.custom_id === wniosek.id),
      comments: comments.filter((comment) => comment.customId === wniosek.id),
    }));

    return { data: wnioskiWithHistoryAndComments };
  } catch (error) {
    console.error("Error fetching wnioski with history and comments:", error);
    throw new Error(
      "Błąd podczas pobierania danych o wnioskach: " + error.message
    );
  }
};

export const updateWniosek = async (wniosek) => {
  try {
    const response = await axios.post(API_ENDPOINT + "/create", { wniosek });
    return response.data;
  } catch (error) {
    throw new Error("Błąd podczas tworzenia wniosku: " + error.message);
  }
};

//*******************************************************EDIT FUNCTIONS */

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

// Fetch row data by ID
export const fetchRowData = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/rejestrWnioskow/${id}`, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching row data:", error);
    throw error;
  }
};

// Fetch comments by ID
export const fetchComments = async (id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/rejestrWnioskow/comments/${id}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch comments for id:", id);
    }
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
};

// Add a new comment
export const addComment = async (id, commentData) => {
  try {
    const headers = await getHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/rejestrWnioskow/comments/${id}`,
      commentData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

// Update field in a record
export const updateField = async (id, fieldData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/rejestrWnioskow/update/${id}`,
      fieldData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

// Save history for a record change
export const saveHistory = async (historyData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/rejestrWnioskow/history`,
      historyData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving history data:", error);
    throw error;
  }
};

// Log history
export const logHistory = async (id, historyData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/history/${id}`,
      historyData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error logging history:", error);
    throw error;
  }
};

export const mapPodpisanyToUsername = (podpisany) => {
  switch (podpisany) {
    case "DOBROWOLSKA-MITRĘGA ANNA":
      return "adobrowolska";
    case "CZERNIKOWSKA DOMINIKA":
      return "dczernikowska";
    case "BRUŚ BARBARA":
      return "bbrus";
    case "MUNIK PIOTR":
      return "pmunik";
    case "MAZUR SABINA":
      return "smazur";
    case "NOWAK PRZEMYSŁAW":
      return "pnowak";
    case "WOJTAŚ MILENA":
      return "mwojtas";
    case "REDUCHA KATARZYNA":
      return "kreducha";
    case "SZKUTNIK AGNIESZKA":
      return "aszkutnik";
    case "ŁOMŻA PRZEMYSŁAW":
      return "plomza";
    default:
      return "";
  }
};

export const deleteWniosek = async (id) => {
  try {
    const headers = await getHeaders();
    const response = await axios.delete(`${API_ENDPOINT}/${id}`, { headers });
    return response; // Zwracaj całą odpowiedź HTTP
  } catch (error) {
    console.error("Error deleting wniosek:", error);
    throw new Error("Błąd podczas usuwania wniosku: " + error.message);
  }
};

export const fetchPowodWpisaniaOptions = async () => {
  try {
    const headers = await getHeaders();
    const response = await axios.get(`${API_BASE_URL}/list/powodWpisania`, {
      headers,
    });
    return response.data; // Zakładam, że dane to tablica powodów
  } catch (error) {
    console.error("Error fetching powod wpisania options:", error);
    throw new Error(
      "Błąd podczas pobierania powodów wpisania: " + error.message
    );
  }
};
