import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css";

const Login = ({ setAuthenticated }) => {
  const [data, setData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const [isLoginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios.interceptors.request.eject();

    return () => {
      axios.interceptors.request.eject();
    };
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      localStorage.removeItem("token");
      localStorage.removeItem("useNewDb");

      const headers = { "Content-Type": "application/json" };
      const url = "https://wega-api.com/login";

      // Wysyłamy pierwsze zapytanie
      const { data: res } = await axios.post(url, data, { headers });
      localStorage.setItem("token", res.token);

      // Wysyłamy drugie zapytanie po otrzymaniu odpowiedzi z pierwszego
      const { data: userData } = await axios.get("https://wega-api.com/me", {
        headers: { Authorization: `Bearer ${res.token}` },
      });

      localStorage.setItem("useNewDb", userData.useNewDb ? "true" : "false");

      const authHeaders = {
        Authorization: `Bearer ${res.token}`,
        "X-Use-New-Db": userData.useNewDb ? "true" : "false",
      };
      axios.defaults.headers.common = authHeaders;

      setTimeout(() => {
        setAuthenticated(true);
        setLoginSuccess(true);
      }, 500);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (isLoginSuccess) {
      toast.success("Login successful!", {
        autoClose: 1000,
        onClose: () => navigate("/user"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSuccess]);

  return (
    <div className={styles.login_container}>
      <div className={styles.login_form_container}>
        <div className={styles.left}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>Zaloguj się</h1>
            <input
              type="text"
              placeholder="Username"
              name="username"
              onChange={handleChange}
              value={data.username}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Hasło"
              name="password"
              onChange={handleChange}
              value={data.password}
              required
              className={styles.input}
            />
            {error && <div className={styles.error_msg}>{error}</div>}
            <button type="submit" className={styles.login_btn}>
              Zaloguj się
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
