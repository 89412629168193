import { Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "./components/Login/Login";
import Signup from "./components/SignUp/SignUp";
import UserDashboard from "./components/UserDashboard/UserDashboard";
import DetailPage from "./components/DetailPage/DetailPage";
import Form from "./components/Form/Form";
import ChatApp from "./components/ChatApp/ChatApp";
import History from "./components/History/History";
import Lists from "./components/Lists/Lists";
import Calculator from "./components/Calculator/Calculator";
import Tranzyt from "./components/Tranzyt/Tranzyt";
import CalculatorZrealizowanePrzygotowane from "./components/Calculator/CalculatorZrealizowanePrzygotowane";
import Traces from "./components/Traces/Traces";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import RejestrWnioskow from "./components/RejestrWnioskow/RejestrWnioskow";
import NowyWniosek from "./components/RejestrWnioskow/NowyWniosek";
import EditWniosek1 from "./components/RejestrWnioskow/EditWniosek1";
import RejestrLists from "./components/RejestrWnioskow/RejestrLists";
import UniwersalListEksport from "./components/CustomsDeclarationForms/UniwersalListEksport/UniwersalListEksport";
import UniwersalListImport from "./components/CustomsDeclarationForms/UniwersalListImport/UniwersalListImport";
import UniwersalListTranzyt from "./components/CustomsDeclarationForms/UniwersalListTranzyt/UniwersalListTranzyt";
Chart.register(ArcElement, Tooltip, Legend);

function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("token") !== null
  );

  const handleLogout = () => {
    localStorage.clear();
    setAuthenticated(false);
  };

  useEffect(() => {
    setAuthenticated(localStorage.getItem("token") !== null);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route
        path="/login"
        element={<Login setAuthenticated={setAuthenticated} />}
      />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/user"
        element={
          authenticated ? (
            <UserDashboard handleLogout={handleLogout} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/detail/:nrRef/:id"
        element={
          authenticated ? <DetailPage /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/form"
        element={authenticated ? <Form /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/chat"
        element={authenticated ? <ChatApp /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/history"
        element={authenticated ? <History /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/list"
        element={authenticated ? <Lists /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/calculator"
        element={
          authenticated ? <Calculator /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/tranzyt"
        element={authenticated ? <Tranzyt /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/calculatorZrealizowan&Przygotowane"
        element={
          authenticated ? (
            <CalculatorZrealizowanePrzygotowane />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/traces"
        element={authenticated ? <Traces /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/adminPanel"
        element={
          authenticated ? <AdminPanel /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/rejestrWnioskow"
        element={
          authenticated ? <RejestrWnioskow /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/nowyWniosek"
        element={
          authenticated ? <NowyWniosek /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/rejestrWnioskow/:id"
        element={
          authenticated ? <EditWniosek1 /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/rejestrLists"
        element={
          authenticated ? <RejestrLists /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/UniwersalListEksport/:nrRef/:id"
        element={
          authenticated ? (
            <UniwersalListEksport />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/UniwersalListImport/:nrRef/:id"
        element={
          authenticated ? (
            <UniwersalListImport />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/UniwersalListTranzyt/:nrRef/:id"
        element={
          authenticated ? (
            <UniwersalListTranzyt />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
    </Routes>
  );
}

export default App;
